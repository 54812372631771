/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import Instruction from "components/instructions/Instruction";
import { InstructionList } from "components/instructions/styles";
import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { useUpdateClientOnboardingMutation } from "services/doverapi/endpoints/company-setup/endpoints";
import { OnboardingSection } from "services/doverapi/endpoints/company-setup/types";
import { useIsLeverAuthValid, useLeverAuthInfo } from "services/doverapi/endpoints/leverData/hooks";
import { ClientOnboardingAtsTypeEnum, ClientOnboardingSetupAtsStateEnum } from "services/openapi";
import { SuccessText } from "views/CompanySetup/components/AtsSettings/styles";

interface LeverComponentProps {
  setupAtsState: ClientOnboardingSetupAtsStateEnum | undefined;
  atsType: ClientOnboardingAtsTypeEnum | undefined;
}

export const LeverOAuthComponent = ({ setupAtsState, atsType }: LeverComponentProps): React.ReactElement => {
  const clientId = useGetClientId();
  const leverAuthValid = useIsLeverAuthValid();

  const [leverAuthPollingInterval, setLeverAuthPollingInterval] = useState<number | undefined>();

  const { isError: leverAuthLoadingError } = useLeverAuthInfo(leverAuthPollingInterval);

  const [updateClientOnboardingMutation] = useUpdateClientOnboardingMutation();

  useEffect(() => {
    // Periodically poll DB to check if client has valid lever refresh tokens
    if (!leverAuthValid || leverAuthLoadingError) {
      setLeverAuthPollingInterval(6000);
      return;
    }

    setLeverAuthPollingInterval(undefined);
  }, [leverAuthValid, leverAuthLoadingError, setLeverAuthPollingInterval]);

  useEffect(() => {
    if (leverAuthValid && atsType !== ClientOnboardingAtsTypeEnum.Lever) {
      updateClientOnboardingMutation({
        id: clientId!,
        data: { atsType: ClientOnboardingAtsTypeEnum.Lever },
        onboardingSection: OnboardingSection.ATS_SETTINGS,
      });
    }
  }, [atsType, leverAuthValid]);

  // ATS type that was last saved
  const switchedFromOtherAtsType = atsType !== ClientOnboardingAtsTypeEnum.Lever;
  const showLeverForm =
    setupAtsState === ClientOnboardingSetupAtsStateEnum.Pending ||
    setupAtsState === ClientOnboardingSetupAtsStateEnum.StartedPendingClientAction ||
    switchedFromOtherAtsType ||
    !leverAuthValid;

  const infoText = (): React.ReactElement => {
    if (leverAuthValid) {
      switch (setupAtsState) {
        case ClientOnboardingSetupAtsStateEnum.Complete:
          return <SuccessText>Dover has successfully connected to Lever! No action is need on your part.</SuccessText>;
        case ClientOnboardingSetupAtsStateEnum.StartedPendingDoverAction:
          return (
            <Box fontStyle="italic">
              Once you click Save and Validate, Dover will begin syncing which can take up to 1 business day.
            </Box>
          );
        default:
          return (
            <SuccessText>Dover has successfully connected to Lever! Press save to confirm your changes.</SuccessText>
          );
      }
    }
    if (leverAuthLoadingError) {
      return (
        <Box fontStyle="italic">
          Dover is unable to verify its connection with your Lever account. Please refresh the page or reauthorize
          Dover. If this error persists, please contact your Dover support.
        </Box>
      );
    }
    return <Box fontStyle="italic">You have not connected your Lever account yet.</Box>;
  };

  return (
    <Box>
      {showLeverForm && (
        <InstructionList>
          <Instruction
            cardHeader={<Body>Log in to Lever as a Super Admin and grant Dover access.</Body>}
            cardInstructionBody={
              <>
                <Spacer height="16px" />
                <Button
                  variant={ButtonVariant.Primary}
                  onClick={(): void => {
                    // specifically use app.dover.io as the lever auth flow is only setup for redirect app.dover.io
                    // test
                    window.open(`https://app.dover.io/lever?client_id=${clientId}`, "_blank", "noopener noreferrer");
                  }}
                >
                  Connect to Lever
                </Button>
              </>
            }
          />
        </InstructionList>
      )}
      {infoText()}
    </Box>
  );
};
